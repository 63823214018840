:root {
  --green: #31924b; 
  --shadow: 2px 4px 24px -8px rgba(0,0,0,0.75);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  font-family: Roboto, sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}