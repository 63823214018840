.Modal {
    height: 100vh;
    width: 100vw;
    background-color: white;
    position: fixed;
    transition: top 200ms;
    z-index: 2;
    left: 0;
}

.Modal.hide {
    top: 100vh;
}

.Modal.show {
    top: 0;
}