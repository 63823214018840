.FeedItem {
    padding: 5vw;
    background-color: white;
    box-shadow: 2px 4px 24px -8px rgba(0,0,0,0.75);
    margin-bottom: 0.5em;
}

.FeedItem:last-child {
    margin-bottom: 0;
}

.FeedItem-head {
    display: flex;
    align-items: center;
}

.FeedItem-head > img {
    height: 3em;
    border-radius: 50%;
}

.FeedItem-head > div {
    margin-left: 1em;
    display: flex;
    flex-wrap: wrap;
}

.FeedItem-date-separator {
    margin: 0 0.2em;
}

.FeedItem-owner-name {
    width: 100%;
    margin-bottom: 0.1em;
    font-weight: bold;
}

.FeedItem-name {
    font-size: 1.5em;
    font-weight: bold;
    display: block;
    margin: 1em 0;
}

.FeedItem-stats {
    display: flex;
    justify-content: space-around;
    font-size: 1.5em;
    align-items: center;
}


.FeedItem-stats-divider {
    font-size: 3em;
    font-weight: 100;
    color: #bbb;
}

.FeedItem-foot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
}

.FeedItem-foot > div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.icon-overlay {
    position: absolute;
    top: 8px;
    color: white;
    font-weight: bold;
    width: 34px;
    text-align: center;
}

.FeedItem-foot > a {
    color: #FC4C02;
    flex: 4;
}

.FeedItem-modal {
    padding: 2vw;
}

.FeedItem-modal-header {
    margin: 1em 0;
    display: flex;
}

.FeedItem-modal-header > h2 {
    flex: 1;
    text-align: center;
    margin-right: 1.5em;
}

.FeedItem-comments-form {
    width: 100%;
    display: flex;
    margin-bottom: 1em;
}

.FeedItem-comments-form > * {
    height: 3.5em;
    font-size: 1em;
    border-radius: 0 !important;
}

.FeedItem-comments-form > input {
    flex: 1;
    padding-left: 0.5em;
    border: 2px var(--green) solid;
}

.FeedItem-comments-form > button {
    width: 5em;
    border-radius: 0 !important;
    background-color: var(--green);
    color: white;
    font-weight: bold;
    border: var(--green) solid;
}

.FeedItem-verify {
    padding: 5vw;
}

.FeedItem-verify {
    text-align: center;
    height: 100%;
}

.FeedItem-verify > select {
    font-size: 1em;
    margin: 1em 0;
    width: 15em;
    height: 3em;
}

.FeedItem-verify > button {
    font-size: 1.5em;
    width: 8em;
    height: 3em;
    border-radius: 10px;
    background-color: var(--green);
    color: white;
    font-weight: bold;
    border: var(--green) solid;
    margin-top: 2em;
}