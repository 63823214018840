.Settings {
    background-color: white;
    height: 100%;
    padding: 5vw;
}

.Settings > button {
    font-size: 1em;
    width: 7em;
    height: 3em;
    border-radius: 0px;
    background-color: var(--green);
    color: white;
    font-weight: bold;
    border: var(--green) solid;
    margin-top: 2em;
}