.Navigation {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: white;
    display: flex;
    height: 4em;
    justify-content: space-around;
    align-items: center;
    box-shadow: var(--shadow);
}

.Navigation > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
} 

.Navigation-padding {
    height: 10em;
}