.CommentRow {
    display: flex;
}

.CommentRow > div {
    min-height: 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.CommentRow-date {
    color: #aaa;
}

.CommentRow > img {
    height: 3em;
    border-radius: 50%;
    margin: 0.5em 0.5em;
}