.Auth {
    flex: 1;
    display: flex;
    background: white 
}

.Auth-title {
    background: rgb(49,146,75);
    background: linear-gradient(135deg, rgba(49,146,75,1) 0%, rgba(151,222,167,1) 100%);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.Auth-logo {
    max-width: 60%;
    animation: opacity-position-anim 700ms ease-out;
}

.Auth-wave {
    position: relative;
    top: 1px;
    animation: height-anim 700ms ease-out;
    transform-origin: 0% 100%;
    width: 100%;
}

.Auth-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Auth-content > a > img {
    margin-bottom: 10em;
    scale: 1.2;
}

.Auth-safari-instructions {
    padding: 2em;
    font-size: 1.2em;
    margin-bottom: 10em;
}

.Auth-safari-instructions > img, .Auth-safari-instructions > div > img  {
    height: 1em;
    vertical-align: top;
}

.Auth-safari-instructions > div {
    display: inline;
}

.Auth-copyright {
    text-align: center;
    color: #aaa;
    margin-bottom: 2em;
}

@media screen and (orientation:portrait) { 
    .Auth {
        flex-direction: column;
    }
}

@media screen and (orientation:landscape) { 
    .Auth {
        flex-direction: row;
    }
    .Auth-wave {
        display: none;
    }
}

@keyframes height-anim {
    0% {
        transform: scaleY(0%);
    }

    100% {
        transform: scaleY(100%);
    }
}

@keyframes opacity-position-anim {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}