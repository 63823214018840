.Social-selector {
    display: flex;
    height: 4em;
    font-weight: bold;
    box-shadow: var(--shadow);    
}

.Social-selector > .selected {
    background-color: var(--green);
    color: white;
}

.Social-selector > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Social-search {
    flex: 1;
    padding-left: 0.5em;
    margin: 2em 5vw;
    width: 90vw;
    height: 3em;
    font-size: inherit;
    font-family: inherit;
    border: 2px var(--green) solid;

}

.FriendRow, .GroupRow {
    display: flex;
    align-items: center;
    min-height: 3em;
    padding: 0 5vw;
    flex-wrap: wrap;
    margin-bottom: 0.5em;
}

.FriendRow-name, .GroupRow-name {
    flex: 1;
    text-wrap: nowrap;
}

.FriendRow > img {
    height: 3em;
    width: 3em;
    border-radius: 50%;
    margin-right: 0.5em;
}

.FriendRow > button, .FriendRow > div > button, .GroupRow > button, .GroupRow > div > button  {
    margin-right: 1em;
    height: 2em;
    padding: 0 1em;
    border: none;
    font-family: inherit;
    background-color: var(--green);
    color: white;
    font-weight: bold;
    border-radius: 3px;
    font-size: inherit;
    margin: 0.5em 1em 0.5em 0;
}

.FriendRow > button:disabled, .GroupRow > button:disabled {
    opacity: 0.5;
}