.Leaderboard {
    background: linear-gradient(135deg, rgba(49,146,75,1) 0%, rgba(151,222,167,1) 100%);
    min-height: 100vh;
}

.Leaderboard-selector {
    display: flex;
    height: 4em;
    font-weight: bold;
    box-shadow: var(--shadow);
    background-color: white;
}

.Leaderboard-selector > .selected {
    background-color: var(--green);
    color: white;
}

.Leaderboard-selector > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Leaderboard-podium {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 2em;
}

.Leaderboard-podium > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    overflow: hidden;
}

.Leaderboard-podium > div:nth-child(1) > .podium-profile {
    animation: icon-fade 600ms ease-in-out;
}

.Leaderboard-podium > div:nth-child(2) > .podium-profile {
    animation: icon-fade 800ms ease-in-out;
}

.Leaderboard-podium > div:nth-child(3) > .podium-profile {
    animation: icon-fade 1000ms ease-in-out;
}

.podium-profile {
    height: 20vw;
    width: 20vw;
    border-radius: 50%;
    border: 3px white solid;
    background-color: white;
    position: relative;
    z-index: 0;
}

.podium-wave {
    /* a weird sizing issue on safari causes 100% width to not be 100%, make it bigger and hide the overflow (.Leaderboard-podium > div)*/
    width: 120%;
    position: relative;
    z-index: 1;
}



.Leaderboard-podium > div > div {
    background-color: white;
    width: 100%;
    text-align: center;
    padding-top: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    position: relative;
    z-index: 1;
   
}

.podium-name {
    color: black;
}

.podium-total {
    font-size: 2em;
    font-weight: bold;
}

.first {
    height: 10em;
    color: #e7c502;
}

.second {
    height: 7em;
    color: #aaaaaa;
}

.third {
    height: 5em;
    color: #cd7f32;
}

.Leaderboard-podium-buffer {
    background-color: white;
    box-shadow: var(--shadow);
    height: 2em;
}

.Leaderboard-list > div {
    padding: 1em;
    margin-bottom: 0.5em 0;
}

.Leaderboard-row {
    background-color: white;
    margin: 0.5em 0;
    display: flex;
    align-items: center;
}

.Leaderboard-row > img {
    height: 2em;
    width: 2em;
    border-radius: 50%;
    margin-right: 1em;
}

.Leaderboard-row > span {
    margin-right: 1em;
}


@keyframes icon-fade {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}