.Tally-padding {
    height: 6em;
}

.Tally {
    position: fixed;
    top: 0;
    width: 100vw;
    background-color: var(--green);
    color: white;
    box-shadow: var(--shadow);
    z-index: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 6em;
}

.Tally > img {
    height: 100%;
}

.Tally > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Tally-money {
    font-size: 1.5em;
}

.Tally-name {
    font-size: 0.75em;
    color: #ddd;
}
